import { render, staticRenderFns } from "./bookRecordSearch.vue?vue&type=template&id=652b15e3&scoped=true&"
import script from "./bookRecordSearch.vue?vue&type=script&lang=js&"
export * from "./bookRecordSearch.vue?vue&type=script&lang=js&"
import style0 from "./bookRecordSearch.vue?vue&type=style&index=0&id=652b15e3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652b15e3",
  null
  
)

export default component.exports