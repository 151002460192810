<template>
  <div class="main-view">
    <div class="content-view">
      <!-- 面包屑 -->
      <div class="bread">
        <bread :fromFather="dataPath"></bread>
      </div>
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">书目搜索</div>
      </div>
      <!-- 搜索框 -->
      <div class="search-view">
        <div class="book-title">书目名称</div>
        <div class="input-view">
          <input class="input-title" type="text" v-model="name" placeholder="请输入关键字">
          <img class="input-img" src="../../../assets/search/search.png" alt="">
        </div>
        <div class="book-type">书目分类</div>
        <div class="type-list">
          <selectList :list="list" :placeholder="placeholder" @chooseMethod="chooseMethod"></selectList>
        </div>
      </div>
      <div class="btn-view">
        <div class="sousuo" :style="{backgroundColor:isSkin}" @click.stop="searchMethod()">搜索</div>
        <div class="cz" @click.stop="resetMethod()">重置</div>
      </div>
      <!-- 搜索列表 -->
      <div class="list">
        <downlaodList v-for="(item,index) in list1" :key="index" :item="item"></downlaodList>
        <div class="page" v-show="pageShow">
          <div class="page1">
            <el-pagination background :current-page="currentPage"
                        :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/tools/bread-box'
import selectList from '../../users/components/box/select-box'
import downlaodList from '../bookDownload/components/downloadList-box'
export default {
  components:{bread,selectList,downlaodList},
  data() {
    return {
      pageShow:false,
      placeholder:'选择分类',
      name:'',
      totals:1,
      currentPage:1,
      pageSize:5,
      title:'',
      dataPath:[{
          path:'bookDownload',
          name:'书目下载',
        },{
          path:'bookRecordSearch',
          name:'书目搜索',
        }
      ],
      category:'',
      list:[],
      listCode:[],
      list1:[]
    }
  },
  created(){
    this.title = this.$route.query.title
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
        this.$store.commit('changSkin',skin)
    }
    if (zskin) {
        this.$store.commit('changZSkin',zskin)
    }
    this.handleCurrentChange(1)
    this.getContentsCategory()
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },   
  },
  methods:{
    /**搜索按钮**/
    searchMethod(){
      this.getBookListContents()
    },
    /**重置按钮**/
    resetMethod(){
      this.name = ''
      this.placeholder = '选择分类'
      this.category = ''
    },
    getContentsCategory(){
      let that = this
      that.$api.getContentsCategory({}).then(res=>{
        if (res.data.code == 0){
          that.list = res.data.data
        }else {
          that.list = []
        }
      })
    },
    getBookListContents(){
      let that = this
      that.$api.getBookListContents({
        params:{
          category:that.category,
          name:that.name,
          pageNum:that.currentPage,
          pageSize:that.pageSize
        }
      }).then(res=>{
        if (res.data.code == 0){
          that.totals = res.data.data.pages
          that.list1 = res.data.data.list
          if (res.data.data.list.length == 0){
            that.pageShow = false
          }else {
            that.pageShow = true
          }
        }else {
          that.list1 = []
          that.pageShow = false
        }
      })
    },
    /**当前页按钮**/ 
    handleCurrentChange(index) {
        this.currentPage = index;
        this.getBookListContents()
        this.$nextTick(function () {
            /**更换分页按钮皮肤**/
            var array = document.querySelectorAll(".el-pagination.is-background .el-pager li");
            if(array && array.length>0){
                array.forEach((item,index1) => {
                    if (index == array[index1].innerText) {
                        array[index1].style.background  = this.isSkin;
                    }else{
                        array[index1].style.background  = "#f4f4f5";
                    }
                })
            }
        });
    },
    chooseMethod(index){
      this.category = index
      this.placeholder = ''
    }
  }
}
</script>

<style scoped lang="scss">
.main-view{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-view{
    width: 1200px;
    .bread{
      margin-top: 50px;
    }
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      margin-top: 10px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
    .search-view{
      width: 100%;
      height: 40px;
      display: flex;
      justify-items: left;
      align-items: center;
      margin-top: 40px;
      .book-title{
        font-size: 14px;
        line-height: 40px;
        color: #666666;
        padding-right: 10px;
      }
      .input-view{
        width: 311px;
        height: 40px;
        position: relative;
        display: flex;
        justify-items: center;
        align-items: center;
        .input-title{
          width: 311px;
          height: 40px;
          background: #FCFCFC;
          border: 1px solid #DDDDDD;
          box-sizing: border-box;
          border-radius: 4px;
          outline:none; //去点击蓝色边框
          padding-left: 10px;
          padding-right: 30px;
          font-size: 15px;
          line-height: 40px;
          color: #666666;
        }
        .input-img{
          position: absolute;
          right: 10px;
        }
      }
      .book-type{
        font-size: 14px;
        line-height: 40px;
        color: #666666;
        margin-left: 50px;
        padding-right: 10px;
      }
      .type-list{
        width: 311px;
        height: 40px;
      }
    }
    .btn-view{
      width: 100%;
      height: 42px;
      margin-top: 40px;
      display: flex;
      justify-items: center;
      align-items: center;
      .sousuo{
        width: 80px;
        height: 42px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 42px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
      }
      .cz{
        width: 80px;
        height: 42px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 16px;
        line-height: 42px;
        color: #666;
        text-align: center;
        margin-left: 50px;
        cursor: pointer;
      }
    }
    .list{
      width: 895px;
      margin-bottom: 40px;
      .page{
        width: 896px;
        padding: 40px 20px 100px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .page1{
          display: flex;
          justify-items: center;
          align-items: center;
        }
      }
    }
  }
}
</style>