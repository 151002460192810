<template>
    <div>
        <div class="content">
            <div class="content-title">{{item.name}}</div>
            <div class="content-content">
                <div class="wj-type">{{'文件类型：'+item.fileType}}</div>
                <div class="wj-dx">{{'文件大小：'+item.fileSize}}</div>
                <div class="download" :style="{backgroundColor:isSkin}">
                    <div class="download-title" @click.stop="downloadMethod(item.fileUrl)">下载</div>
                    <img src="../../../../assets/home/downlaod-img.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
    },
    methods:{
      /**下载记录**/
      getDownloadContents(){
        let that = this
        that.$api.getDownloadContents({
          params:{
            id:that.item.id
          }
        }).then(res=>{
          if (res.data.code == 0){

          }
        })
      },
        /**下载**/
        downloadMethod(data){
          this.getDownloadContents()
          let arr = data.split('.');
          if (arr[arr.length-1] == 'txt' || arr[arr.length-1] == 'jpg' || arr[arr.length-1] == 'png') {
            this.downloadUrlFile(data);
          }else{
            let aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = data;
            aLink.setAttribute("download", name);
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink); //下载完成移除元素
            window.URL.revokeObjectURL(data);
          }
        },
      /**
       * 获取页面文件名
       * @param url 文件url
       */
      downloadUrlFile(url) {
        url = url.replace(/\\/g, '/');
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            // 获取文件blob数据并保存
            let fileName = this.getFileName(url);
            this.saveAs(xhr.response, fileName);
          }
        };
        xhr.send();
      },

      /**
       * URL方式保存文件到本地
       * @param data 文件的blob数据
       * @param name 文件名
       */
      saveAs(data, name) {
        let urlObject = window.URL || window.webkitURL
        let export_blob = new Blob([data])
        let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = name;
        save_link.click();
      },

      /**
       * 根据文件url获取文件名
       * @param url 文件url*/
      getFileName(url) {
        let num = url.lastIndexOf('/') + 1
        let fileName = url.substring(num)
        //把参数和文件名分割开
        fileName = decodeURI(fileName.split("?")[0]);
        return fileName;
      },
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 100%;
    height: 124px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0px;
    margin-top: 20px;
    padding: 20px 20px 20px 20px;
    .content-title{
        font-size: 16px;
        line-height: 22px;
        text-align: justify;
        color: #333333;
        height: 44px;
    }
    .content-content{
        display: flex;
        justify-items: flex-start;
        align-items: center;
        position: relative;
        height: 40px;
        .wj-type{
            font-size: 12px;
            line-height: 17px;
            text-align: justify;
            color: #666666;
            position: absolute;
            bottom: 0px;
            left: 0px;
        }
        .wj-dx{
            font-size: 12px;
            line-height: 17px;
            text-align: justify;
            color: #666666;
            margin-left: 20px;
            position: absolute;
            bottom: 0px;
            left: 120px;
        }
        .download{
            position: absolute;
            right: 20px;
            display: flex;
            justify-items: flex-start;
            align-items: center;
            width: 106px;
            height: 42px;
            border-radius: 4px;
            cursor: pointer;
            .download-title{
                font-size: 16px;
                line-height: 42px;
                text-align: center;
                color: #FFFFFF;
                padding: 0px 10px 0px 25px;
            }
        }
    }
}
</style>